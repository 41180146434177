require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');

import {
  FEED_WIDGET_ID_PROD,
  POST_WIDGET_ID_PROD,
  CATEGORY_MENU_WIDGET_ID,
  ARCHIVE_WIDGET_ID,
  POST_LIST_WIDGET_ID,
  TAG_CLOUD_WIDGET_ID,
} from './constants/widgets';
import { createNoopController } from './controllers/createNoopController';
import { createOldPostPageController } from './controllers/createOldPostPageController';
import { createFeedPageController } from '../feed-page/controller';
import { createPostPageController } from '../post-page/controller';
import { createPostListController } from '../post-list-widget/controller';
import { createCategoryMenuController } from '../category-menu-widget/controller';
import { createTagCloudController } from '../tag-cloud-widget/controller';
import { createArchiveController } from '../archive-widget/controller';
import fetchExperimentsRequest from '../common/store/experiments/experiments-request';
import { createMonitoring } from '../common/services/create-monitoring';
import getEnvironment from '../common/services/get-environment';

const CONTROLLER_FACTORY_MAP = {
  [FEED_WIDGET_ID_PROD]: createFeedPageController,
  [POST_WIDGET_ID_PROD]: createPostPageController,
  [CATEGORY_MENU_WIDGET_ID]: createCategoryMenuController,
  [ARCHIVE_WIDGET_ID]: createArchiveController,
  [POST_LIST_WIDGET_ID]: createPostListController,
  [TAG_CLOUD_WIDGET_ID]: createTagCloudController,
};

const bundleName = 'viewer-script';

const context = { captureToSentry: console.error };
const initializedControllers = {};

const getFactory = (controllerConfig) => {
  const { wixCodeApi, type, compId } = controllerConfig;
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const isInWorker = typeof WorkerGlobalScope !== 'undefined';
  const controllerId = `${type}-${compId}`;

  // there is a bug in viewerScriptWrapper where it initialized controller again when going into preview
  if ((isEditor || isPreview) && initializedControllers[controllerId]) {
    if (type === POST_WIDGET_ID_PROD) {
      return createOldPostPageController;
    }
    return createNoopController;
  }
  initializedControllers[controllerId] = true;

  return CONTROLLER_FACTORY_MAP[controllerConfig.type] || createNoopController;
};

export const initAppForPage = (appParams, api, wixCodeApi, platformAPIs) => {
  if (process.env.NODE_ENV !== 'production' || wixCodeApi.location.query.debug) {
    console.log('initAppForPage', { appParams, api, wixCodeApi });
  }

  try {
    context.captureToSentry = createMonitoring({
      appParams,
      wixCodeApi,
      platformAPIs,
      origin: bundleName,
    }).captureToSentry;
  } catch (error) {
    console.error(error);
  }

  try {
    context.experimentsPromise = fetchExperimentsRequest({ wixCodeApi, baseUrls: appParams.baseUrls, bundleName });
  } catch (error) {
    context.captureToSentry(error);
  }

  return Promise.resolve();
};

export const createControllers = (controllerConfigs) => {
  return controllerConfigs.map((controllerConfig) => {
    try {
      const factory = getFactory(controllerConfig);
      return factory(controllerConfig, controllerConfigs, context);
    } catch (error) {
      context.captureToSentry(error);
      return undefined;
    }
  });
};

export const exports = {};
